$(document).ready(function(){
	jQuery.each(jQuery('[data-role="filtro-tipologias"]'), function () {
		var $selector = $(this),
		api_route = $selector.data('action');

		$selector.change(function(event){
			event.preventDefault();
			var tipologia = $(this).val() || '',
			$filtro_ciudades = $('[data-role="filtro-ciudades"]'),
			$filtro_comunas = $('[data-role="filtro-comunas"]'),
			$options_ciudades = $('[data-role="filtro-ciudades"] option');
			// ciudades_filter = $filtro_ciudades.data('filter') || false;

			// $filtro_comunas.addClass('hide');
			// $($filtro_comunas.parent()).addClass('hide');
				
			$filtro_ciudades.addClass('loading');
			var placeholder = $($options_ciudades[0]).html();
			$($options_ciudades[0]).html('Cargando...');

			$options_ciudades.removeClass('hide').removeAttr('selected');
			
			// if((tipologia !== '' && $filtro_ciudades.val() == '') || ( tipologia !== '' && ciudades_filter !== false) ){
			// if(($filtro_ciudades.val() == '') || ( ciudades_filter !== false) ){
				$.ajax({
					url: api_route,
					type: 'POST',
					dataType: 'json',
					data: {
						tipologia: tipologia
					}
				}).done(function (response) {
					var ciudades_ids = response.ciudades.ids;

					console.log('done');
					console.log(response);

					jQuery.each(jQuery($options_ciudades), function () {
						var $option  = $(this);

						if(jQuery.inArray($option.data('id'), ciudades_ids) == -1 && $option.attr('value') !== ''){
							$option.addClass('hide');
						}
					});

					$($options_ciudades[0]).html(placeholder);
					$filtro_ciudades.removeClass('loading');
				});
			// }
		});
	});

	jQuery.each(jQuery('[data-role="filtro-ciudades"]'), function () {
		var $selector = $(this),
		api_route = $selector.data('action');

		$selector.change(function(event){
			event.preventDefault();

			var ciudad = $(this).val() || '',
			$filtro_comunas = $('[data-role="filtro-comunas"]'),
			$options_comunas = $('[data-role="filtro-comunas"] option'),
			$filtro_tipologias = $('[data-role="filtro-tipologias"]'),
			$options_tipologias = $('[data-role="filtro-tipologias"] option');

			var tipologia = $filtro_tipologias.val();

			// if(ciudad !== ''){
				var placeholder_comunas = $($options_comunas[0]).html();
				var placeholder_tipologias = $($options_tipologias[0]).html();
				
				$filtro_comunas.addClass('loading');
				$($options_comunas[0]).html('Cargando...');
				$options_comunas.removeClass('hide').removeAttr('selected');

				if(tipologia == ''){
					$filtro_tipologias.addClass('loading');
					$($options_tipologias[0]).html('Cargando...');
					$options_tipologias.removeClass('hide').removeAttr('selected');
				}
				
				$.ajax({
					url: api_route,
					type: 'POST',
					dataType: 'json',
					data: {
						ciudad: ciudad,
						tipologia: tipologia
					}
				}).done(function (response) {
					console.log(response);
					if(response.comunas_status == 'found'){
						var comunas_ids = response.comunas.ids;

						console.log('found');
						console.log(response.comunas);

						jQuery.each(jQuery($options_comunas), function () {
							var $option  = $(this);

							if(jQuery.inArray($option.data('id'), comunas_ids) == -1 && $option.attr('value') !== ''){
								$option.addClass('hide');
								// $option.prev().addClass('hide');
							}
						});

						$($options_comunas[0]).html(placeholder_comunas).attr('selected', true);
						$filtro_comunas.removeClass('loading').removeClass('hide').attr('active', true);
						$filtro_comunas.prev().removeClass('hide');
						$($filtro_comunas.parents('.form-control')).removeClass('hide');
					}else{						
						$($options_comunas[0]).html(placeholder_comunas).attr('selected', true);
						$filtro_comunas.addClass('hide').attr('active', false);
						$filtro_comunas.prev().addClass('hide');
						$($filtro_comunas.parents('.form-control')).addClass('hide');
					}

					if(response.tipologias_status == 'found' && tipologia == ''){
						console.log('done tipologias');
						console.log(response);

						$($filtro_tipologias.parents('.form-control')).removeClass('hide');

						var tipologias_ids = response.tipologias.ids;

						jQuery.each(jQuery($options_tipologias), function () {
							var $option  = $(this);

							if(jQuery.inArray($option.data('id'), tipologias_ids) == -1 && $option.attr('value') !== ''){
								$option.addClass('hide');
							}
						});

						$($options_tipologias[0]).html(placeholder_tipologias).attr('selected', true);
						$filtro_tipologias.removeClass('loading').removeClass('hide').attr('active', true);
					}
				});
			// }
		});
	});

	jQuery.each(jQuery('.form-control__select.hide'), function () {
		$(this).prev().addClass('hide')
	});
});